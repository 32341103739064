import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import "./Rentals.css";
import { useState } from "react";

const Rentals = () => {
    // const columns = ["Features"];
    // const keystoneDetails = [
    //     "3 slides with a huge living area",
    //     "Full kitchen for those gourmet meals",
    //     "1 bedroom",
    //     "1 full bath",
    //     "Fireplace (electric)",
    //     "Hardwood cabinets",
    //     "Full master closet",
    //     "Lots of storage space"
    // ];
    //
    // const sportsmanDetails = [
    //     "2 slides with a cozy and cute living area with 2 couches (no dinette)",
    //     "Bar seating at counter",
    //     "Entertainment center",
    //     "Full kitchen with microwave",
    //     "1 bedroom",
    //     "1 bath",
    //     "Lots of storage and a full closet in the bedroom",
    // ];
    //
    // const [imgSrcA1Active, setImgSrcA1Active] = useState(false);
    // const [imgSrcA2Active, setImgSrcA2Active] = useState(false);
    // const [imgSrcA3Active, setImgSrcA3Active] = useState(false);
    //
    //
    // const [imgSrcB1Active, setImgSrcB1Active] = useState(false);
    // const [imgSrcB2Active, setImgSrcB2Active] = useState(false);
    // const [imgSrcB3Active, setImgSrcB3Active] = useState(false);


    return (<>
        <div style={{ padding: "3em", margin: "3em auto", border: "1px solid black", borderRadius: "1em", maxWidth: "1180px" }}>
            <div style={{ marginBottom: "2em", fontSize: "x-large", display: "flex", justifyContent: "space-between" }}>
                <div>No rentals are available at this time</div>
            </div>
            <div style={{ fontSize: "larger", textAlign: "justify" }}>
                Join our email list to be notified when availability returns! Enter your email below and stay updated. Check back regularly for updates.
            </div>
        </div>
    </>);
};

export default Rentals;
