import "./header-navigation.css";
import { Link, useLocation } from "react-router-dom";
import {useEffect, useMemo, useState} from "react";

const HeaderNavigation = () => {
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false);
    const location = useLocation();
    useEffect(() => {
        console.log(location.pathname);
    }, [location.pathname]);

    const paths = useMemo(() => [
        {
            to: "/",
            label: "Home",
            className: "Nav-link",
        },
        {
            to: "/gallery",
            label: "Gallery",
            className: "Nav-link",
        },
        {
            to: "/directions",
            label: "Directions",
            className: "Nav-link",
        },
        {
            to: "/park-rules",
            label: "Park Rules",
            className: "Nav-link",
        },
        {
            to: "/rates",
            label: "Rates",
            className: "Nav-link",
        },
        {
            to: "/rentals",
            label: "Rentals",
            className: "Nav-link",
        },
        {
            to: "/contact",
            label: "Contact",
            className: "Nav-link",
        },
    ]
        .map((item) => {
            if (item.to === location.pathname) {
                return {
                    ...item,
                    className: "Nav-link Nav-link-active",
                };
            }
            return item;
        }), [location.pathname]);
    return (
        <div>
            <div className="Header-nav-container">
                <div>
                    <div className="Nav-link-logo">
                        <Link to="/">
                            <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                                 alt="WSR Logo"
                                 className="Logo"
                            />
                        </Link>
                    </div>
                </div>
                <div style={{
                    paddingBottom: "3em",
                    display: "flex",
                    gap: "4em"
                }}>
                    {paths && paths.map((p) =>
                        <Link to={p.to} className={p.className}>{p.label}</Link>
                    )}
                </div>
            </div>
            <div className="Mobile-menu">
                <div className="Mobile-menu-bar">
                    <div>
                        <a href="tel:+17754652409">
                            <i className="fa fa-phone Mobile-menu-icons" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div className="Mobile-menu-logo-container">
                        <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                             alt="WSR Logo - mobile menu"
                             className="Mobile-menu-logo"
                        />
                    </div>
                    <div onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
                        <i className="fa fa-bars Mobile-menu-icons"></i>
                    </div>
                </div>
                {displayMobileMenu && (
                    <div className="Mobile-menu-links"
                         onClick={() =>setDisplayMobileMenu(!displayMobileMenu)}>
                        <Link to="/" className="Nav-link">Home</Link>
                        <Link to="/gallery" className="Nav-link">Gallery</Link>
                        <Link to="/directions" className="Nav-link">Directions</Link>
                        <Link to="/park-rules" className="Nav-link">Park Rules</Link>
                        <Link to="/rates" className="Nav-link">Rates</Link>
                        <Link to="/rentals" className="Nav-link">Rentals</Link>
                        <Link to="/contact" className="Nav-link">Contact</Link>
                    </div>
                )}
            </div>
        </div>);
}

export default HeaderNavigation;
